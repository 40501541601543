import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import './assets/css/App.css';
import BingoCard from './BingoCard';
import WandbNotification from './components/thewandb/sheetsNotification';
import setupWebSocketConnection from './components/thewandb/wandbNotification';
import WhenPlane from './components/whenplane/WhenPlane';

function App() {
  const [theme, setTheme] = useState('default'); // Add a state for theme

  useEffect(() => {
    const params = queryString.parse(window.location.search);
    const initialTheme = params.theme || 'default';
    setTheme(initialTheme); // Set the initial theme based on URL parameter

    // Dynamically import the theme-specific CSS
    switch (initialTheme) {
      case 'floatplane':
        require('./assets/css/BingoCard-FloatPlane.css');
        break;
      case 'ltx23':
        require('./assets/css/BingoCard-LTX23.css');
        break;
      case 'bread':
        require('./assets/css/BingoCard-BREAD.css');
        break;
      case 'winter':
        require('./assets/css/BingoCard-WINTER23.css');
        break;
      case 'winterdark':
        require('./assets/css/BingoCard-WINTER23DARK.css');
        break;
      case 'afterdark':
        require('./assets/css/BingoCard-AFTERDARK.css');
        break;
      case 'bringus':
        require('./assets/css/BingoCard-BRINGUS-DEMO.css');
        break;
      default:
        require('./assets/css/BingoCard.css');
    }

    const socket = setupWebSocketConnection((bingoData) => {
      // Handle bingo notifications
    }, (stateData) => {
      if (stateData.themeChange) {
        setTheme(stateData.themeChange); // Change theme based on WebSocket message
        // Import CSS for new theme dynamically if needed
      }
    });

    return () => {
      if (socket) socket.disconnect();
    };
  }, []);

  const backgroundStyle = {
    backgroundImage: `linear-gradient(to top, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%), url(https://pbs.floatplane.com/stream_thumbnails/5c13f3c006f1be15e08e05c0/873100211224047_1706322471714.jpeg)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    transition: 'background-image 1s ease-in-out',
    zIndex: -3
  };

  return (
    <div className="App" style={backgroundStyle}> {/* Apply backgroundStyle here */}
      <WandbNotification />
      <BingoCard theme={theme} /> {/* Pass theme as prop */}
    </div>
  );
}

export default App;
